import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from "@angular/material/button";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JwtInterceptor } from './authentications/JwtInterceptor.service';
import { FooterComponent } from './components/footer/footer.component';
import { ForgotPasswordPageComponent } from './components/forgot-password-page/forgot-password-page.component';
import { HeaderComponent } from './components/header/header.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PopupSearchResultSectionComponent } from './components/popup-search-result-section/popup-search-result-section.component';
import { SearchPopupComponent } from './components/search-popup/search-popup.component';
import { FooterService } from './services/footer.service';
import { HeaderWidgetComponent } from './shared/components/header-widget/header-widget';
import { SharedModule } from './shared/shared.module';
import { ApplicationInitializerFactory, httpLoaderFactory } from './translation.config';
import { MetaService } from "./services/meta.service";
import { ResetPasswordPageComponent } from './components/reset-password-page/reset-password-page.component';
import { ActiveAccountComponent } from './components/active-account/active-account.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { LogoutComponent } from './components/logout/logout.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from "ngx-google-analytics";
import { environment } from "../environments/environment";
import { UserService } from './shared/services/user.service';
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { AuthenticationService } from "./authentications/authentication.service";
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ImpersonateComponent } from './impersonate/impersonate.component';
registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HeaderWidgetComponent,
    NavbarComponent,
    FooterComponent,
    SearchPopupComponent,
    PopupSearchResultSectionComponent,
    ForgotPasswordPageComponent,
    ResetPasswordPageComponent,
    ActiveAccountComponent,
    NotFoundComponent,
    ForbiddenComponent,
    LogoutComponent,
    ImpersonateComponent
   ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    MatButtonModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [TranslateService, Injector, AuthenticationService],
      multi: true
    },
    FooterService,
    UserService,
    MetaService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    /**
     * Change the local to replace 'MM/dd/yyyy' to 'dd/MM/yyyy' in date pickers
     */
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
