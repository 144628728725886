import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

@Component({
  template: `

  `,
  selector: 'fspro-impersonate'
})
export class ImpersonateComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const email = params['email'];
      const isRHLU = params['rhlu'];
      document.cookie = '_wanna_be=' + email + '|' + isRHLU + '; path=/ ; samesite=Lax';
      window.location.replace('/');
    })
  }

}
